<template>
    <div class="news xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>行业新闻</h3>
                    <p>当前位置：<router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>行业新闻</a></p>
                </div>
            </div>
        </div>
        <div class="news-wrap clearfix">
            <div class="news-left left">
                <div class="newsdetail-box">
                    <h3 class="detail-tit">医疗科技行业的业态概述：智慧医疗、互联网医疗、移动医疗</h3>
                    <div class="detail-date">时间：<span>2017-03-16</span>   来源：<span style="color: #1fb7a6;">求蛋白</span></div>
                    <div class="detail-intro">
                        <p>由于多种复杂因素的影响，我国医疗领域长期面临着资源匮乏、分布不均匀、配置不合理等问题。而随着互联网的普及以及技术的高速发展，互联网与医疗的融合打造了医疗科技，并着力于提高我国医疗产业发展，提升国民医疗体验。</p>
                    </div>
                    <div class="detail-info">
                        <img src="../../assets/img/news_img.jpg" alt="">
                        <p>中国医疗行业是在上世纪80年代开始结合互联网基础上去释放潜力的，随着我国经济程度的发展和人民生活品质的改进，使得医疗服务对医疗健康的需求不断增长，但是我国医疗领域长期面临着资源匮乏，分布不均匀，配置不合理等问题导致供求问题日益尖锐。</p>

                        <h4>如何进行医疗体制改革，切实解决“看病难“和“看病贵“成为了当今社会亟需解决的问题所在。</h4>

                        <p>在我国“互联网+”大力推行的同时，新兴的互联网医疗为改革指引了一个有效的探索方向。借助互联网跨越时空的优势，通过医患互动、共享医疗信息等方式在有效降低医疗成本解决“看病贵”的同时，也助力改善医疗资源分配不均等而形成的“看病难”的问题。</p>

                        <p>与此同时，患者间也可通过互联网平台分享最新医疗咨询、线下就医感受等内容有助于改善当下医患关系紧张，提高我国医疗产业发展，全面提升我国国民健康水平。</p>

                        <h4>医疗科技的发展主要经历了三个扩展时期：</h4>

                        <h4>第一阶段：</h4>

                        <p>自从互联网在2000年普及后，在医疗系统（HIS、RIS）中出现了早期的BBS在线问诊平台，主要是用户发布病情贴，在线医生解答病情分析，平台的主要盈利模式是广告收入。</p>

                        <p>关于广告收入的鼻祖就是百度医疗，大家都知道自从出现了“魏则西”“卖贴吧”等事件之后，百度在这个领域是越走越远了。</p>

                    </div>
                </div>
            </div>
            <div class="news-right right">
                <div class="hot-news">
                    <div class="hot-newstit">
                        <h3>热门新闻</h3>
                    </div>
                    <div class="hot-news-list">
                        <div class="hot-news-list">
                            <div class="hot-news-img-first">
                                <img src="../../assets/img/news_img.jpg" alt="">
                            </div>
                            <div class="hot-news-info-first">
                                <p>古巴与印度：全民免费医疗的宿命</p>
                                <span>2021-05-12</span>
                            </div>
                        </div>
                        <div class="hot-news-list clearfix">
                            <div class="hot-news-img left">
                                <img src="../../assets/img/news_img.jpg" alt="">
                            </div>
                            <div class="hot-news-info left">
                                <p>古巴与印度：全民免费医疗的宿命</p>
                                <span>2021-05-12</span>
                            </div>
                        </div>
                        <div class="hot-news-list clearfix">
                            <div class="hot-news-img left">
                                <img src="../../assets/img/news_img.jpg" alt="">
                            </div>
                            <div class="hot-news-info left">
                                <p>古巴与印度：全民免费医疗的宿命</p>
                                <span>2021-05-12</span>
                            </div>
                        </div>
                        <div class="hot-news-list clearfix">
                            <div class="hot-news-img left">
                                <img src="../../assets/img/news_img.jpg" alt="">
                            </div>
                            <div class="hot-news-info left">
                                <p>古巴与印度：全民免费医疗的宿命</p>
                                <span>2021-05-12</span>
                            </div>
                        </div>
                        <div class="hot-news-list clearfix">
                            <div class="hot-news-img left">
                                <img src="../../assets/img/news_img.jpg" alt="">
                            </div>
                            <div class="hot-news-info left">
                                <p>古巴与印度：全民免费医疗的宿命</p>
                                <span>2021-05-12</span>
                            </div>
                        </div>
                        <div class="hot-news-list clearfix">
                            <div class="hot-news-img left">
                                <img src="../../assets/img/news_img.jpg" alt="">
                            </div>
                            <div class="hot-news-info left">
                                <p>古巴与印度：全民免费医疗的宿命</p>
                                <span>2021-05-12</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hot-news">
                    <div class="hot-newstit">
                        <h3>联系我们</h3>
                    </div>
                    <div class="contact-us-img">
                        <img src="../../assets/img/contact-us-img.jpg" width="100%" alt="">
                    </div>
                    <div class="contact-us-info">
                        <p><i class="el-icon-s-promotion"></i>河南省-郑州市-二七区 大学路与政通路交叉口升龙天玺6楼622室</p>
                        <!--<p><i class="el-icon-phone"></i>18639512888</p>-->
                    </div>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>

<script>
    import ftCom from '../../components/footer';
    import nhCom from "../../components/navheader";
    import rtBar from "../../components/right";
    export default {
        name: "newsDetail",
        components: {
            ftCom,
            nhCom,
            rtBar
        },
    }
</script>

<style scoped>
    .news{
        background: #f7f7f7;
    }
    .banner {
        width: 100%;
        height: 160px;
        background: url("../../assets/img/news_banner.jpg")center no-repeat;
    }

    .banner-wrap {
        position: relative;
        width: 1200px;
        height: 100%;
        margin: 0 auto;
    }

    .banner-text {
        float: left;
    }

    .banner-text h3 {
        padding-top: 35px;
        font-size: 26px;
        font-weight: normal;
        color: #ffffff;
    }

    .banner-text p {
        font-size: 14px;
        color: #ffffff;
        margin-top: 20px;
    }

    .banner-text p a {
        color: #ffffff;
        font-size: 14px;
        margin: 0 5px;
    }
    /*---------*/
    .news-wrap{
        width: 1200px;
        margin: 35px auto;
    }
    .news-wrap .news-left{
        width: 880px;
    }
    .news-wrap .news-right{
        width: 300px;
    }
    .newsdetail-box{
        padding: 25px;
        box-sizing: border-box;
        background: #FFFFFF;
    }
    .newsdetail-box .detail-tit{
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .newsdetail-box .detail-date span{
        margin-right: 15px;
    }
    .newsdetail-box .detail-intro{
        margin: 15px 0;
        padding: 15px;
        background: #fafafa;
        border: 1px solid #f0f0f0;
        border-left: 12px solid #f0f0f0;
    }
    .newsdetail-box .detail-intro p{
        color: #666666;
        font-size: 15px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
    }
    .newsdetail-box .detail-info{
        text-align: center;
    }
    .newsdetail-box .detail-info img{
        max-width: 100%;
    }
    .newsdetail-box .detail-info p{
        line-height: 1.7;
        text-align: left;
        font-size: 15px;
    }
    .newsdetail-box .detail-info h4{
        line-height: 1.7;
        padding: 15px 0;
        text-align: left;
        font-size: 15px;
    }
    /*----------*/
    .hot-news{
        padding: 15px;
        box-sizing: border-box;
        background: #FFFFFF;
    }
    .hot-news .hot-newstit{
        border-bottom: 1px solid #e5e5e5;
    }
    .hot-news h3{
        display: inline-block;
        border-bottom: 2px solid #1fb7a6;
        font-size: 18px;
        color: #333333;
    }
    .hot-news-list{
        margin: 15px 0;
    }
    .hot-news-img-first{
        width: 100%;
        height: 150px;
        background: #e5e5e5;
        line-height: 150px;
        overflow: hidden;
    }
    .hot-news-list .hot-news-img{
        width: 102px;
        height: 60px;
        background: #e5e5e5;
        line-height: 60px;
        overflow: hidden;
    }
    .hot-news-list .hot-news-img img,.hot-news-list .hot-news-img-first img{
        width: 100%;
    }
    .hot-news-list .hot-news-info{
        width: calc(100% - 112px);
        margin-left: 10px;
    }
    .hot-news-list .hot-news-info-first p{
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .hot-news-list .hot-news-info p{
        color: #333333;
        line-height: 1.3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    .hot-news-list .hot-news-info span,.hot-news-list .hot-news-info-first span{
        color: #999999;
        font-size: 12px;
    }
    .contact-us-img{
        margin-top: 15px;
        width: 100%;
        height: 200px;
        overflow: hidden;
    }
    .contact-us-info i{
        font-size: 16px;
        margin-right: 10px;
    }
</style>
